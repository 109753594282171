import React from "react";
import AccentBox from "../../Components/AccentBox/AccentBox";
import CourseGuide from "../../Components/CourseGuide/CourseGuide";
import CourseBlock from "../../Components/CoursesBlock/CourseBlock";
import Text from "../../Components/Text/Text";
import { courses } from "../../Data/course_data";
import "./Courses.css";

export default function Courses() {
  return (
    <div className="courses" id="nav-courses-scroll">
      <AccentBox width={500} height={1000} left={-520} top={900} />
      <Text tag="h2" text="Our courses" />

      <div className="courses-container">
        <div>
          <CourseBlock content={courses} />
        </div>
        <CourseGuide />
      </div>
    </div>
  );
}
