import React from "react";
import Button from "../Button/Button";
import "./Form.css";

export default function Form({
  formContent,
  formAction,
  isDisabled,
  isNoSubmit,
  isRequired,
}) {
  return (
    <div className="form-container">
      <form onSubmit={(e) => formAction(e)}>
        {formContent.map(
          ({ label, onchange, value, type, isTextArea }, index) => {
            return (
              <div key={index}>
                <div className="form-error">
                  {!value && isRequired && <p>*Please complete this field</p>}
                </div>
                {isTextArea ? (
                  <textarea
                    placeholder={label}
                    rows={5}
                    onChange={(e) => onchange(e.target.value)}
                  />
                ) : (
                  <input
                    placeholder={label}
                    onChange={(e) => onchange(e.target.value)}
                    value={value}
                    type={type}
                  />
                )}
              </div>
            );
          }
        )}
        {!isNoSubmit && (
          <Button text="Submit" type="submit" disabled={isDisabled} />
        )}
      </form>
    </div>
  );
}
