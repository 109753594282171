import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import Text from "../../Components/Text/Text";
import Form from "../../Components/Form/Form";
import AccentBox from "../../Components/AccentBox/AccentBox";

export default function Contact() {
  const [Name, setName] = useState("");
  const [cNumber, setNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [Query, setQuery] = useState("");

  const [isFormSending, setIsFormSending] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);

  const isButtonDisabled = () => {
    return Name && cNumber && Email && Query ? false : true;
  };

  const formContent = [
    {
      label: "Name",
      onchange: setName,
      value: Name,
      type: "text",
    },
    {
      label: "Contact number",
      onchange: setNumber,
      value: cNumber,
      type: "number",
    },
    {
      label: "Email address",
      onchange: setEmail,
      value: Email,
      type: "text",
    },
    {
      label: "Query",
      onchange: setQuery,
      value: Query,
      isTextArea: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormSending(true);

    if (Name && Query && cNumber && Email) {
      let enrollTemplateParams = {
        to_name: Name,
        to_number: cNumber,
        to_email: Email,
        to_query: Query,
        sponsor_type: "-",
        sponsor_count: "-",
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE,
          process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT,
          enrollTemplateParams,
          process.env.REACT_APP_EMAILJS_USER
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            setIsFormSending(false);
            setSendStatus(true);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
  };

  return (
    <div className="contact" id="nav-contact-scroll">
      <AccentBox width={400} height={800} left={-350} />
      <Text tag="h2" text="Contact us" />
      {sendStatus ? (
        <>
          <Text tag="h5" text="Successfully received" />
          <Text tag="h5" text="We will get back to you within 48 hours." />
        </>
      ) : (
        <Form
          formContent={formContent}
          formAction={handleSubmit}
          isDisabled={isButtonDisabled() || isFormSending}
          isRequired
        />
      )}
    </div>
  );
}
