export const courses = [
  {
    name: "Quran/Qaida",
    levels: [
      {
        level: 1,
        shortDescription:
          "Designed for beginners who have no, or very little prior, Quran education. Irrespective of age or gender, this course will cover the essentials and fundamentals of the Arabic language, enabling the student to be able to read the Quran as well as understand the basics of our religion.",
        infoList: [
          "Learn the Arabic alphabet and identify the origins, with the correct pronunciation.",
          "Understand Arabic letters with their joint forms (Beginning, middle, ending)",
          "Learn how to join letters to make words",
          "Understand the basics of Tajweed",
          "Memorize the last five surahs of the Quran",
          "Learn Duas for various occasions",
          "Learn etiquettes and mannerism in Islam",
          "Learn the 99 names of Allah ",
        ],
        requirements:
          "This is the most basic level course, therefore the student is not required to have any knowledge of Arabic in order to join this course. However, little knowledge of Arabic alphabets will be a plus point for the student. We request you attend lessons on time and cooperate with the teacher.",
      },
      {
        level: 2,
        shortDescription:
          "Designed to cover every aspect of reading the Quran from the basic level to an advanced level irrespective of age or gender. This course is designed for students who have some prior knowledge of the Quran but are not fluent in reading and want to improve their reading skills. Successful completion of this course will enable students to read the Quran fluently, without any hesitation, as well as empowering them with the fundamentals of the religion and making them an integral part of the society.",
        infoList: [
          "Read the Full Quran with accuracy in its Arabic accent",
          "Memorize the last 10 Surahs of the Quran, using Tajweed rules.",
          "Memorize Surah Yaseen, Surah Rahman and Surah Mulk with its correct Tajweed",
          "Implementation of Tajweed",
          "Learn the stop and pause signs in the Quran",
          "Learn duas for various occasions",
          "Perfect your Salah",
          "Learn the fundamentals of your deen",
        ],
        requirements:
          "Student is required to know how to read Arabic words. Any student who has completed Qaida and has basic knowledge of vowels, sukoon, madd, tanween and shaddah can join this course. We request you attend lessons on time and cooperate with the teacher.",
      },
    ],
  },
  {
    name: "Memorization of Quran",
    levels: [
      {
        shortDescription:
          "This course will focus on memorizing the Quran (It can be selected chapters, Juzz Amma, Juzz Tabarak or entire Quran), depends on the student preference and goals. In this course students will be learning how to recite Quran with its proper articulation and rules, tajweed and tarteel. Students will be expected and guided to memorize chapter by chapter of the Quran with the correct rulings.",
        longDescriptionList: [
          "To keep the interest level of student high, lessons will be mixed with several other activities e.g. Learning basic Arabic/tajweed and fundamentals of Islam (Five pillars, duas, Stories of the prophets will be covered along with interactive and fun activities However, the overall focus will be to complete the Full Quran by heart. ",
          "After finishing this course, students will be able to read Quran by heart fluently with correct pronunciation (like a native Arab) along with knowledge of the fundamentals of Islam, giving the student the confidence to lead others in prayer specially in the month of Ramadan for Taraweeh prayers as well as become a teacher to others",
        ],
        requirements:
          "Student must be able to read Quran fluently and should be aware of basic tajweed rules.",
      },
    ],
  },
  {
    name: "Arabic",
    levels: [
      {
        level: 1,
        shortDescription:
          "This course is for beginners who have no prior knowledge or very little knowledge of the arabic language and would like to understand the language of the Quran aswell as be able to communicate on a very basic level in the arabic language the course is based on fus-ha dialect which is the dialect of the Quran.\nThe aim of this course is to build a strong foundation about the core principles governing Arabic language, to provide introduction to Arabic grammar and to develop grasp on beginners Arabic vocabulary which shows up throughout the Quran.\nSuccessful completion of this course will enable students to analyze the grammar of Surahs such as al-Fatihah, Al-Falaq, and An-Nas and understand the basic concepts and sentence structure of Arabic.",
        infoList: [
          "Understand parts of the Quran and Hadith",
          "Read some basic Arabic literature ",
          "Build accurate sentences grammatically and syntactically",
          "Recognize and use Arabic sounds accurately",
          "Contact with native speakers in different situations",
          "Build Arabic structures and sentences",
        ],
      },
      {
        level: 2,
        shortDescription:
          "This course is for intermediate level student who already has knowledge of foundation of the Classical Arabic and is aware of basic grammar and vocabulary.\nThis is a comprehensive course for learning the Arabic language. It combines modern Arabic vocabulary with Islamic terminology used in the Quran and Sunnah, an ongoing series which will aim to get students to master some of the Arabic material and eventually gain a grasp over the understanding of the Quranic Arabic. Classical Arabic courses are based on fus-ha dialect which is the dialect of the Quran.",
        infoList: [
          "Arabic Reading",
          "Arabic Writing",
          "Understanding Arabic",
          "Speaking Arabic",
          "Understand the Quran and Hadith",
          "Read the Arabic literature",
          "Build accurate sentences grammatically and syntactically",
          "Recognize and use Arabic sounds accurately",
          "Contact with native speakers in different situations",
          "Building Arabic structures and sentences",
        ],
      },
    ],
  },
];

export const aboutUs = [
  {
    heading: "Progress Report",
    content:
      "We send regular reports to you in your email or phone to keep track of lesson activities and course progress. Find out how much of the course is remaining and how many units student has completed. Read about your kids strong and weak points by going through teacher comments.",
  },
  {
    heading: "Private & Group Quran Tutoring with a family discount",
    content:
      "Our sessions are 1 to 1; however, if the students are from the same household and would like a group session we give a 50% price deduction after the first student, this is limited to a group of 3 so to ensure the teacher can give sufficient time to each of the students without compromising on the quality of education.",
  },
  {
    heading: "Expert Quran Teachers",
    content:
      "Certified native male & female Quran tutors available to teach 24/7. You choose the schedule and we will match you with a tutor. All of our staff have a minimum of three years teaching experience",
  },
  {
    heading: "Assignments & Exams",
    content:
      "Students are regularly assessed with course exams to ensure that milestones are being well met.",
  },
  {
    heading: "Digital Study Materials",
    content:
      "Digital study materials are shared with students during class and available for after-class reviewing.",
  },
];

export const coursePricing = {
  1: {
    info: [
      "4 sessions a month",
      "30 minute sessions",
      "£9 per 30 minute session",
      "£36 a month",
      "Male and female tutors",
    ],
    session: 7,
    month: 56,
  },
  2: {
    info: [
      "8 sessions a month",
      "30 minute sessions",
      "£7 per 30 minute session",
      "£56 a month",
      "Male and female tutors",
    ],
    session: 7,
    month: 56,
  },
  3: {
    info: [
      "12 sessions a month",
      "30 minute sessions",
      "£6.50 per 30 minute session",
      "£78 a month",
    ],
    session: 6.5,
    month: 78,
  },
  4: {
    info: [
      "16 sessions a month",
      "30 minute sessions",
      "£6 per 30 minute session",
      "£96 a month",
    ],
    session: 6,
    month: 96,
  },
  5: {
    info: [
      "20 sessions a month",
      "30 minute sessions",
      "£5.50 per 30 minute session",
      "£110 a month",
    ],
    session: 5.5,
    month: 110,
  },
};
