import React, { useState } from "react";
import Text from "../Text/Text";
import "../../Pages/Sponsor/Sponsor.css";

export default function SponsorInfo() {
  const [expandState, setExpandState] = useState("");

  return (
    <div>
      <Text
        tag="p"
        text="The Messenger of Allah (saw) said, ‘When a person dies, his deeds come to an end except for three: Sadaqah Jariyah (a continuous charity), or knowledge from which benefit is gained, or a righteous child who prays for him’. (Hadith, Muslim)"
      />
      <br />
      <Text
        tag="p"
        text="Imagine how many verses a hafiz or an alim can convey in a lifetime! Imagine how much reward can be gained through sitting at home. All this will be an ongoing charity for you as well as knowledge from which benefit is being gained combined with the prayers of the children and scholars in your favour."
      />
      <br />
      <p
        className="expand-sponsor"
        onClick={() =>
          expandState === "student"
            ? setExpandState("")
            : setExpandState("student")
        }
      >
        Sponsoring a student
      </p>

      {expandState === "student" && (
        <div className="expand-content">
          <Text
            tag="p"
            text="We are committed to empowering children from underprivileged families and offering them a Qur’anic education through our Hifz Sponsorship Programme. This provides them with the opportunity to read and understand the Holy Qur’an, as well as provides access to education for disadvantaged or impoverished children who may not otherwise get the opportunity to learn."
          />
          <br />
          <Text
            tag="p"
            text="On average, it takes Four to Five years for children to complete their studies, and throughout the duration of the studies, mysheikhonline will provide you with regular feedback on the child’s progress, as well as an annual progress report about the progress of the student you sponsor. You'll receive a welcome pack so you can get to know your sponsored student with further details of the program."
          />
          <br />
          <Text
            tag="p"
            text="You can give £30 a month by setting a direct debit with us or may chose to make one payment of £360 or two payments of £180 which will provide a child with an entire year of Islamic education. Your generosity won’t just provide the promise of a brighter future – it will also ensure a safe haven for a child currently in need.
                Sponsor a student for £30 a month"
          />
        </div>
      )}

      <p
        className="expand-sponsor"
        onClick={() =>
          expandState === "teacher"
            ? setExpandState("")
            : setExpandState("teacher")
        }
      >
        Sponsoring a teacher
      </p>

      {expandState === "teacher" && (
        <div className="expand-content">
          <Text
            tag="p"
            text="During the Islamic golden age, the scholars of Islam were some of the worlds leading figures mysheikhonline aims to promote continued excellence in Islamic leadership by sponsoring and taking care of the finical difficulties of our scholars, so that they can devote themselves to spreading the knowledge of Islam and Contribute towards the future of our generations without having to worry about making a living for themselves and taking care of their family financially."
          />
          <br />
          <Text tag="p" text="Sponsor a teacher for £30 a month" />
        </div>
      )}
    </div>
  );
}
