import React from "react";
import AccentBox from "../../Components/AccentBox/AccentBox";
import Text from "../../Components/Text/Text";
import { aboutUs } from "../../Data/course_data";
import "./About.css";

export default function About() {
  return (
    <div className="about" id="nav-about-scroll">
      <AccentBox width={500} height={1300} rotation="less" right={-400} />
      <Text tag="h2" text="Why choose us" />
      <Text
        tag="p"
        text="At My Sheikh Online we offer comprehensive online Quran/Arabic classes for all age groups at different levels, ranging from beginners to those advancing on a journey towards Hifz or becoming a scholar."
      />
      <br />
      <Text
        tag="p"
        text="All our courses are structured with planned guidelines. Every teacher is assigned tasks to ensure the student achieves desired goals in the allocated time period. The progress of the student is monitored and we ensure that the student receives quality education. Regular exercises are given to instill what you learn."
      />

      {aboutUs.map(({ heading, content }, index) => {
        return (
          <div className="about-list" key={index}>
            <Text tag="h3" text={heading} />
            <Text tag="p" text={content} />
          </div>
        );
      })}
    </div>
  );
}
