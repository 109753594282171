import React, { useState } from "react";
import Text from "../../Components/Text/Text";
import Button from "../../Components/Button/Button";
import "./Sponsor.css";
import SponsorInfo from "../../Components/SponsorInfo/SponsorInfo";
import SponsorForm from "../../Components/SponsorForm/SponsorForm";
import emailjs from "@emailjs/browser";

export default function Sponsor() {
  const [isSponsorOpen, setIsSponsorOpen] = useState(false);

  const [Name, setName] = useState("");
  const [cNumber, setNumber] = useState("");
  const [Email, setEmail] = useState("");

  const [SponsorType, setSponsorType] = useState("");
  const [SponsorNumber, setSponsorNumber] = useState("");

  const [isFormSending, setIsFormSending] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);

  const handleSubmit = (level) => {
    setIsFormSending(true);
    if (Name && SponsorType && cNumber && Email && SponsorNumber) {
      let enrollTemplateParams = {
        to_name: Name,
        level: level,
        to_number: cNumber,
        to_email: Email,
        to_query: "-",
        sponsor_type: SponsorType,
        sponsor_count: SponsorNumber,
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE,
          process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT,
          enrollTemplateParams,
          process.env.REACT_APP_EMAILJS_USER
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            setIsFormSending(false);
            setSendStatus(true);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
  };

  const isButtonDisabled = () => {
    if (isSponsorOpen) {
      return Name && cNumber && Email && SponsorType && SponsorNumber
        ? false
        : true;
    }
  };

  return (
    <div id="sponsor-scroll" className="sponsor-page">
      <Text tag="h2" text="Sponsor" />

      {!isSponsorOpen ? (
        <SponsorInfo />
      ) : sendStatus ? (
        <>
          <Text tag="h5" text="Successfully received" />
          <Text tag="h5" text="We will get back to you within 48 hours." />
        </>
      ) : (
        <SponsorForm
          Name={Name}
          setName={setName}
          cNumber={cNumber}
          setNumber={setNumber}
          Email={Email}
          setEmail={setEmail}
          setSponsorNumber={setSponsorNumber}
          SponsorNumber={SponsorNumber}
          setSponsorType={setSponsorType}
          handleSubmit={handleSubmit}
        />
      )}

      {!sendStatus && (
        <Button
          text="Sponsor"
          onClickAction={() =>
            isSponsorOpen ? handleSubmit() : setIsSponsorOpen(true)
          }
          disabled={isButtonDisabled() || isFormSending}
        />
      )}
    </div>
  );
}
