import "./App.css";
import Footer from "./Components/Footer/Footer";
import NavBar from "./Components/NavBar/NavBar";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Courses from "./Pages/Courses/Courses";
import Home from "./Pages/Home/Home";
import Sponsor from "./Pages/Sponsor/Sponsor";

function App() {
  return (
    <div className="App">
      {/* Navigation Bar */}
      <NavBar />
      {/* Home starts here */}
      <Home />
      {/* Courses starts here */}
      <Courses />
      {/* Sponsor starts here */}
      <Sponsor />
      {/* About us starts here */}
      <About />
      {/* Contact form starts here */}
      <Contact />
      {/* Footer here */}
      <Footer />
    </div>
  );
}

export default App;
