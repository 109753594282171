import React from "react";
import "./AccentBox.css";

export default function AccentBox({
  left,
  right,
  top,
  bottom,
  width,
  height,
  rotation,
}) {
  return (
    <div className="accent-box-container">
      <div
        className={`accent-box ${rotation}`}
        style={{
          width: width,
          height: height,
          left: left,
          right: right,
          top: top,
          bottom: bottom,
        }}
      />
    </div>
  );
}
