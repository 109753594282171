import React, { useState } from "react";
import InfoModal from "../InfoModal/InfoModal";
import "./Footer.css";

export default function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    document.body.style.overflow = "unset";
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="footer">
        <p
          onClick={() => {
            setIsModalOpen(true);
            document.body.style.overflow = "hidden";
          }}
        >
          Terms & conditions
        </p>
      </div>

      {isModalOpen && <InfoModal closeModal={closeModal} />}
    </>
  );
}
