import React from "react";
import "./NavBar.css";

export default function NavBar() {
  const scroll = (id) => {
    const section = document.querySelector(id);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="nav-bar">
      <div className="nav-logo">
        <img alt="Logo" src={require("../../Assets/logo.png")} />
      </div>
      <div className="nav-links">
        <p
          onClick={(e) => {
            scroll("#nav-home-scroll");
          }}
        >
          Home
        </p>
        <p
          onClick={(e) => {
            scroll("#nav-courses-scroll");
          }}
        >
          Courses
        </p>
        <p
          onClick={(e) => {
            scroll("#nav-about-scroll");
          }}
        >
          About
        </p>
        <p
          onClick={(e) => {
            scroll("#nav-contact-scroll");
          }}
        >
          Contact
        </p>
      </div>
    </div>
  );
}
