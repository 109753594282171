import React from "react";
import Text from "../Text/Text";
import "./CourseGuide.css";

export default function CourseGuide() {
  return (
    <div className="course-guide">
      <Text tag="h5" text="How it works" />

      <p className="guide-number">1</p>
      <div className="guide-stick" />
      <p className="guide-content">
        After the free trial lesson, the student enrols onto their desired
        course and is given a Skype/Zoom ID.
      </p>

      <div className="guide-stick" />
      <p className="guide-number">2</p>
      <div className="guide-stick" />
      <p className="guide-content">
        The teacher calls the student at the pre-selected time. He/She will
        share their screen with the student. Note that students will be provided
        with a copy of the lesson material being taught.
      </p>

      <div className="guide-stick" />
      <p className="guide-number">3</p>
      <div className="guide-stick" />
      <p className="guide-content">
        Our team of supervisors will supervise the class to ensure quality. In
        case of children, parents may also choose to supervise the classes and
        are welcome to do so.
      </p>

      <div className="guide-stick" />
      <p className="guide-number">4</p>
      <div className="guide-stick" />
      <p className="guide-content">
        At the end of the lesson, the students are given homework for the next
        session. In case of children feedback is sent to parents via message or
        email with teachers’ comments.
      </p>
    </div>
  );
}
