import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Text from "../../Text/Text";
import Button from "../../Button/Button";
import "./CourseContent.css";
import CourseBook from "../../CourseBook/CourseBook";

export default function CourseContent({ content, name }) {
  const [courseIndex, setCourseIndex] = useState([]);

  const [enrollIndex, setEnrollIndex] = useState("");
  const [isEnrollOpen, setIsEnrollOpen] = useState(false);
  const [courseData, setCourseData] = useState({});

  const [DaysSelected, setDaysSelected] = useState("");
  const [Students, setStudents] = useState("");
  const [Name, setName] = useState("");
  const [cNumber, setNumber] = useState("");
  const [Email, setEmail] = useState("");

  const [isFormSending, setIsFormSending] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);

  const handleSubmit = (level) => {
    setIsFormSending(true);
    if (Name && Students && cNumber && Email && DaysSelected && name) {
      let enrollTemplateParams = {
        to_name: Name,
        course_name: name,
        level: level,
        to_number: cNumber,
        to_email: Email,
        days_per_week: DaysSelected,
        student_count: Students,
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE,
          process.env.REACT_APP_EMAILJS_TEMPLATE__ENROLL,
          enrollTemplateParams,
          process.env.REACT_APP_EMAILJS_USER
        )
        .then(
          function (response) {
            setIsFormSending(false);
            setSendStatus(true);
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
  };

  const isButtonDisabled = (inputIndex) => {
    if (isEnrollOpen && inputIndex === enrollIndex) {
      return Name && cNumber && Email && DaysSelected && Students
        ? false
        : true;
    }
  };

  const openEnroll = (index, level) => {
    setCourseData(level);
    setIsEnrollOpen(true);
    setEnrollIndex(index);
  };
  return (
    <>
      {content.map(
        (
          {
            level,
            shortDescription,
            infoList,
            requirements,
            longDescriptionList,
          },
          index
        ) => {
          return (
            <div className="course-level" key={`${index}2`}>
              {level && <Text tag="h4" text={`Level ${level}`} />}

              {isEnrollOpen && enrollIndex === index ? (
                sendStatus ? (
                  <>
                    <Text tag="h6" text="Successfully enrolled" />
                    <Text
                      tag="h6"
                      text="We will get back to you within 48 hours."
                    />
                    <Text
                      tag="h6"
                      text="Do not hesitate to contact us in the meantime."
                    />
                  </>
                ) : (
                  <CourseBook
                    level={courseData}
                    Name={Name}
                    setName={setName}
                    cNumber={cNumber}
                    setNumber={setNumber}
                    Email={Email}
                    setEmail={setEmail}
                    DaysSelected={DaysSelected}
                    setDaysSelected={setDaysSelected}
                    setStudents={setStudents}
                  />
                )
              ) : (
                <>
                  <Text tag="p" text={shortDescription} />

                  {courseIndex.includes(index) && (
                    <div className="course-info-list">
                      {infoList && (
                        <ul>
                          {infoList.map((item) => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      )}

                      {longDescriptionList &&
                        longDescriptionList.map((item) => <p>{item}</p>)}

                      {requirements && (
                        <div>
                          <Text tag="h6" text="Requirements" />
                          <Text tag="p" text={requirements} />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className="course-level-btn-container">
                <Button
                  isTertiary
                  text={
                    isEnrollOpen && enrollIndex === index ? "Back" : "Read more"
                  }
                  onClickAction={() =>
                    isEnrollOpen && enrollIndex === index
                      ? setIsEnrollOpen(false)
                      : setCourseIndex([...courseIndex, index])
                  }
                  disabled={
                    (isEnrollOpen && enrollIndex === index
                      ? false
                      : courseIndex.includes(index)) || isFormSending
                  }
                />
                <Button
                  isSecondary
                  text={"Enroll"}
                  onClickAction={() => {
                    isEnrollOpen && enrollIndex === index
                      ? handleSubmit(level)
                      : openEnroll(index, level);
                  }}
                  disabled={isFormSending || isButtonDisabled(index)}
                />
              </div>
            </div>
          );
        }
      )}
    </>
  );
}
