import React from "react";
import "./SponsorForm.css";
import Text from "../Text/Text";
import Form from "../Form/Form";

export default function SponsorForm({
  Name,
  setName,
  cNumber,
  setNumber,
  Email,
  setEmail,
  setSponsorType,
  setSponsorNumber,
  SponsorNumber,
}) {
  const formContent = [
    {
      label: "Name",
      onchange: setName,
      value: Name,
      type: "text",
    },
    {
      label: "Contact number",
      onchange: setNumber,
      value: cNumber,
      type: "number",
    },
    {
      label: "Email address",
      onchange: setEmail,
      value: Email,
      type: "text",
    },
  ];

  return (
    <div className="sponsor-form">
      <Text text="Enter details" tag="h6" />
      <Text
        tag="p"
        text="Please complete the form and we'll be in touch for payment collection. JazakAllah Khayr"
      />
      <select
        name="type"
        id="type"
        onChange={(e) => setSponsorType(e.target.value)}
      >
        <option value="">Please select sponsee</option>
        <option value={"Student"}>Student</option>
        <option value={"Teacher"}>Teacher</option>
      </select>

      <select
        name="count"
        id="count"
        onChange={(e) => setSponsorNumber(e.target.value)}
      >
        <option value="">Select no. of sponsees</option>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </select>

      <Text tag="p" text="Price is £30 per sponsee" />
      <Text tag="p" text={`Total cost: £${SponsorNumber * 30} `} />

      <div className="sponsor-book-form">
        <Form formContent={formContent} isNoSubmit />
      </div>
    </div>
  );
}
