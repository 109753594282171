import React from "react";
import "./Text.css";

export default function Text({ tag, text }) {
  return (
    <div className="Heading">
      <p className={tag}>{text}</p>
    </div>
  );
}
