import React from "react";
import AccentBox from "../../Components/AccentBox/AccentBox";
import Button from "../../Components/Button/Button";
import Text from "../../Components/Text/Text";
import "./Home.css";

export default function Home() {
  const scroll = (isSponsor) => {
    const section = document.querySelector(
      isSponsor ? "#sponsor-scroll" : "#nav-courses-scroll"
    );
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="home" id="nav-home-scroll">
      <AccentBox width={700} height={1000} right={-250} top={-100} />
      <Text tag="h1" text="My Sheikh Online" />
      <Text
        tag="h3"
        text="Under the supervision of scholars from madinah and world renowned
        institutes"
      />

      <div className="home-image">
        <img src={require("../../Assets/home.png")} alt="Home" />
      </div>
      <div className="home-btn-container">
        <Button text="View Courses" onClickAction={() => scroll(false)} />
        <Button text="Sponsor" onClickAction={() => scroll(true)} />
      </div>
    </div>
  );
}
