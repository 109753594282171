import React from "react";
import "./Button.css";

export default function Button({
  isSecondary,
  isTertiary,
  onClickAction,
  text,
  type,
  disabled,
}) {
  const buttonType = () => {
    if (isSecondary) {
      return "secondary";
    }
    if (isTertiary) {
      return "tertiary";
    }
    return "primary";
  };

  return (
    <button
      className={`btn-main ${buttonType()}`}
      onClick={onClickAction}
      disabled={disabled}
      type={type}
    >
      {text}
    </button>
  );
}
