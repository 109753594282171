import React from "react";
import { coursePricing } from "../../Data/course_data";
import Text from "../Text/Text";
import Form from "../Form/Form";
import "./CourseBook.css";

export default function CourseBook({
  level,
  Name,
  setName,
  cNumber,
  setNumber,
  Email,
  setEmail,
  DaysSelected,
  setDaysSelected,
  setStudents,
}) {
  const formContent = [
    {
      label: "Name",
      onchange: setName,
      value: Name,
      type: "text",
    },
    {
      label: "Contact number",
      onchange: setNumber,
      value: cNumber,
      type: "number",
    },
    {
      label: "Email address",
      onchange: setEmail,
      value: Email,
      type: "text",
    },
  ];

  return (
    <div className="course-book">
      <div className="course-book-info">
        <Text tag="h6" text="Student Details" />

        {coursePricing[DaysSelected] &&
          coursePricing[DaysSelected].info.map((i) => (
            <ul key={i}>
              <li>{i}</li>
            </ul>
          ))}
        <select
          name="days"
          id="days"
          onChange={(e) => setDaysSelected(e.target.value)}
        >
          <option value="">Select Days a week</option>
          <option value={1}>1 Day a week</option>
          <option value={2}>2 Days a week</option>
          <option value={3}>3 Days a week</option>
          <option value={4}>4 Days a week</option>
          <option value={5}>5 Days a week</option>
        </select>
        <Text
          tag="p"
          text="50% price deduction after the first student. This is limited to a group of 3."
        />
        <select
          name="students"
          id="students"
          onChange={(e) => setStudents(e.target.value)}
        >
          <option value="">Select no. students</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </select>
      </div>
      <div className="course-book-form">
        <Text tag="h6" text="Your Details" />
        <Text
          tag="p"
          text="Please enter contact details and we'll get back to you with the next step."
        />

        <Form formContent={formContent} isNoSubmit />
      </div>
    </div>
  );
}
