import React from "react";
import Text from "../Text/Text";
import CourseContent from "./CourseContent/CourseContent";
import "./CourseBlock.css";

export default function CourseBlock({ content }) {
  return content.map(({ name, levels }, index) => {
    return (
      <div className="course-block" key={index}>
        <Text tag="h3" text={name} />
        <CourseContent content={levels} name={name} />
      </div>
    );
  });
}
