import React from "react";
import Text from "../Text/Text";
import "./InfoModal.css";

export default function InfoModal({ closeModal }) {
  return (
    <div className="info-modal">
      <div className="info-modal-content">
        <div className="info-modal-top-bar">
          <Text tag="h3" text="Terms & Conditions" />
          <p onClick={closeModal} className="info-modal-close-button">
            X
          </p>
        </div>

        <p className="info-modal-tc">
          This Privacy Policy governs the manner in which mysheikhonline.com
          collects, uses, maintains and discloses information collected from
          students, parents and users ( “User”) of the www.mysheikhonline.com
          website (“Site”). This privacy policy applies to the Site and all
          products and services offered by my sheikh online. We may collect
          personal identification information from parents/students/users in
          different ways, not limited to, when Users visit our site, sign up on
          the website, and in connection with other activities, services,
          features or resources we make available on our Site. Users may be
          asked for, as appropriate, name, email address, mailing address, phone
          number. We will collect personal identification information from
          Parents/Students/Visitors only if they voluntarily submit such
          information to us. Users can always refuse to provide personally
          identification information, except that it may prevent them from
          engaging in certain activities related to our services.
          <br /> <strong>Non-personal identification information</strong>
          <br /> We may also collect non-personal identification information
          about Users whenever they interact with our website. Non-personal
          identification information may include the internet browser name, the
          type of computer device and technical information about Users’ means
          of connection to our Site, such as the ISP (internet service provider)
          and operating system and other similar information.
          <br /> <strong>Use of cookies</strong>
          <br /> Cookies are files with small amount of data, which may include
          an anonymous unique identifier. Cookies are sent to your browser from
          a web site and stored on your computer's hard drive. Like many sites,
          we use "cookies" to collect information. You can instruct your browser
          to refuse all cookies or to indicate when a cookie is being sent.
          <br />
          <strong>Security of your personal information</strong>
          <br /> We will take reasonable technical and organizational
          precautions to prevent the loss, misuse or alteration of your personal
          information. Of course, data transmission over the internet is
          inherently insecure, and we cannot guarantee the 100% security of data
          sent over the internet.
          <br /> <strong>Sharing your personal information</strong>
          <br /> We do not disclose, sell, trade, or rent Users’ personal
          identification information to any third party. We may share generic
          aggregated demographic information not linked to any personal
          identification information regarding parents/students/visitors with
          our trusted business partners and affliates for the purposes outlined
          above.
          <br />
          <strong>
            Compliance with children’s online privacy protection act
          </strong>
          <br />
          Persons under the age of 18 are not eligible to use the site
          unsupervised and we ask them not to submit any personal information or
          content to us without the consent of a guardian. If you are under the
          age of 18, you may only use this site in conjunction with and under
          the supervision of your parents or guardians.
          <br /> <strong>Fees</strong>
          <br /> Tuition fee is required to be paid in advance for each monthly
          or quarterly subscription.
          <br />
          <strong>Absences, Cancellations and lesson re-arrangements</strong>
          <br /> For every cancellation, we will try to arrange a make-up lesson
          however My Sheikh Online is not liable to arrange makeup lesson
          (Re-arranged lessons) for "Student Absences". Hence, it is recommended
          to check your microphone and speaker settings before attending lesson,
          and also try to maintain your attendance. In case student cannot
          attend a lesson, he/she can cancel it 24 hours before its starting
          time. Please note that lesson will not be cancelled if admin is not
          informed in time. Hence, in cases of short-notice in which there are
          only 30-45 minutes left for the lesson to start, its your
          responsibility to send a message to your teacher mentioning that you
          will not be attending today, so that teacher should not wait for you
          in classroom.
          <br /> <strong>Refund policy </strong>
          <br /> If a student subscribes for a month, it will not be refunded
          even if it is cancelled by the student in the middle OR beginning of a
          subscription. Once a student subscribes, he will have access to the
          full course material. It will not be fair towards the institute to
          refund the subscription amount after access to the full course library
          has been given.
          <br /> <strong>Changes To This Privacy Policy</strong>
          <br /> This Privacy Policy is effective as of (add date) and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on this page. We reserve the right to update or change our Privacy
          Policy at any time and you should check this Privacy Policy
          periodically. Your continued use of the Service after we post any
          modifications to the Privacy Policy on this page will constitute your
          acknowledgment of the modifications and your consent to abide and be
          bound by the modified Privacy Policy. If we make any material changes
          to this Privacy Policy, we will notify you either through the email
          address you have provided us, or by placing a prominent notice on our
          website.
          <br /> <strong>Your acceptance of these terms</strong>
          <br /> By using this website, you signify your acceptance of this
          policy. If you do not agree to this policy, please do not use our
          website. Your continued use of the website following the posting of
          changes to this policy will be deemed your acceptance of those
          changes.
          <br /> <strong>Contacting us</strong> <br /> If you have any questions
          or query about this Privacy Policy, the practices of this website, or
          your dealings with this site, please contact us at
          info@mysheikhonline.com
        </p>
      </div>
    </div>
  );
}
